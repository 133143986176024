import {
  configApiRef,
  createApiFactory,
  createComponentExtension,
  createPlugin,
  createRoutableExtension, discoveryApiRef,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';
import {Entity} from "@backstage/catalog-model";
import {CapApiRef, CapClient} from './api';

export const CAP_ANNOTATION_SLUG = 'platform.cloudbear.it/app-slug';

export const capPlugin = createPlugin({
  id: 'cap',
  apis: [
    createApiFactory({
      api: CapApiRef,
      deps: { configApi: configApiRef, discoveryApi: discoveryApiRef },
      factory: ({ configApi, discoveryApi }) =>
        new CapClient({
          discoveryApi,
          baseUrl: configApi.getOptionalString('cap.baseUrl'),
        }),
    }),
  ],
  routes: {
    root: rootRouteRef,
  },
});

export const CapPage = capPlugin.provide(
  createRoutableExtension({
    name: 'CapPage',
    component: () =>
      import('./components/ExampleComponent').then(m => m.ExampleComponent),
    mountPoint: rootRouteRef,
  }),
);

export const EntityCapOverview = capPlugin.provide(
  createComponentExtension({
    name: 'EntityCapOverview',
    component: {
      lazy: () =>
        import('./components/widgets/Overview').then((m) => m.Overview),
    },
  })
);

export const EntityCapDeploymentsTable = capPlugin.provide(
  createComponentExtension({
    name: 'EntityCapDeploymentsTable',
    component: {
      lazy: () =>
        import('./components/widgets/DeploymentsTable').then((m) => m.DeploymentsTable),
    },
  })
);

export const isCapSlugAnnotationAvailable = (entity: Entity) =>
Boolean(entity.metadata.annotations?.[CAP_ANNOTATION_SLUG]);

export const isCapAvailable = (entity: Entity) =>
  isCapSlugAnnotationAvailable(entity)
