import { DiscoveryApi } from '@backstage/core-plugin-api';
import {
  Application, ApplicationDiff, ApplicationGet,
  ApplicationList,
  CapApi, Deployment, Environment, JsonApiObject, Response,
} from './CapApi';

export class CapClient implements CapApi {
  discoveryApi: DiscoveryApi;
  baseUrl: string;

  constructor({
                discoveryApi,
                baseUrl = 'https://platform.cloudbear.it/api',
              }: {
    discoveryApi: DiscoveryApi;
    baseUrl?: string;
  }) {
    this.discoveryApi = discoveryApi;
    this.baseUrl = baseUrl.endsWith('/') ? baseUrl : `${baseUrl}/`;
  }

  private async callApi<T>(
    path: string,
    query: { [key in string]: any },
  ): Promise<T> {
    const apiUrl = `${await this.discoveryApi.getBaseUrl('proxy')}/cap`;
    const response = await fetch(
      `${apiUrl}/${path}?${new URLSearchParams(query).toString()}`,
    );
    if (response.status === 200) {
      return (await response.json()).data as T;
    }
    throw new Error('oh no');
  }

  private async callJsonApi(
    path: string,
    query: { [key in string]: any },
  ): Promise<Response> {
    const apiUrl = `${await this.discoveryApi.getBaseUrl('proxy')}/cap`;
    const response = await fetch(
      `${apiUrl}/${path}?${new URLSearchParams(query).toString()}`,
      {
        headers: {
          Accept: "application/vnd.api+json",
        },
      }
    );
    if (response.status === 200) {
      return (await response.json());
    }

    throw new Error('oh no');
  }

  async getApplications(slug: string): Promise<ApplicationList> {
    const applications = await this.callApi<Application[]>(
      `applications.json`,
      {
        slug: slug,
      },
    );
    return {
      getApplicationsData: applications,
    };
  }

  async getApplication(slug: string): Promise<ApplicationGet> {
    const response = await this.callJsonApi(
      `applications.json`,
      {
        slug: slug,
        include: "Deployments,Deployments.Environments",
      },
    );

    const app = response.data[0] as JsonApiObject<Application>
    const deployRelations = app.relationships.deployments.data

    const deployments: Deployment[] = [];
    // @ts-ignore
    for (const relation of deployRelations) {
      const deployment = this.getIncluded<Deployment>(response.included, relation.type, relation.id);

      const environment = this.getIncluded<Environment>(
        response.included,
        // @ts-ignore
        deployment.relationships.environment.data.type,
        // @ts-ignore
        deployment.relationships.environment.data.id
      );

      deployments.push({
        id: deployment.id,
        currentVersion: deployment.attributes.currentVersion,
        desiredVersion: deployment.attributes.desiredVersion,
        environment: environment.attributes
      })
    }

    return {
      id: app.id,
      getApplicationData: app.attributes,
      deployments: deployments,
    };
  }

  async getDiff(id: string, envSlug: string): Promise<ApplicationDiff> {
    const response = await this.callApi<ApplicationDiff>(
      `applications/${id}/diff.json`,
      {
        env: envSlug,
      },
    );

    return {
      patch: response.patch,
    };
  }

  private getIncluded<T>(included: JsonApiObject<any>[], type: string, id: string): JsonApiObject<T> {
    for (const include of included) {
      if (include.type === type && include.id === id) {
        return include;
      }
    }

    throw new Error('not found')
  }
}
