import {TableColumn} from "@backstage/core-components";
import {CatalogTableRow} from "@backstage/plugin-catalog";
import {EntityTechInsightsScoreColumn} from "../catalog/EntityTechInsightsScoreColumn";
import React from "react"

export type createScoreColumnProps = {
  checkIds: string[];
};
export function createScoreColumn({checkIds}: createScoreColumnProps): TableColumn<CatalogTableRow> {
  return {
    title: 'Checks',
    render: entity => (
      <EntityTechInsightsScoreColumn
        entity={entity.entity}
        checkIds={checkIds}
      />
    )
  }
}
