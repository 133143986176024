import React from 'react';
import {Entity, getCompoundEntityRef} from "@backstage/catalog-model";
import {techInsightsApiRef} from "@backstage-community/plugin-tech-insights";
import {useApi} from "@backstage/core-plugin-api";
import {useAsync} from "react-use";
import {Progress} from "@backstage/core-components";
import Alert from '@material-ui/lab/Alert';
import {List, ListItem, ListItemText, makeStyles} from "@material-ui/core";
import {BackstageTheme} from "@backstage/theme";

const useStyles = makeStyles((theme: BackstageTheme) => ({
  list: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
  },
  listItem: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
  },
  listItemText: {
    paddingRight: theme.spacing(0.5),
  },
}));

export type EntityTechInsightsScoreColumnProps = {
  entity: Entity;
  checkIds: string[];
};
export const EntityTechInsightsScoreColumn = ({entity, checkIds}: EntityTechInsightsScoreColumnProps) => {
  const api = useApi(techInsightsApiRef);
  const { namespace, kind, name } = getCompoundEntityRef(entity);
  const { value, loading, error } = useAsync(
    async () => await api.runChecks({ namespace, kind, name }, checkIds),
  );
  const classes = useStyles();

  const checkResults = value;
  if (checkResults === undefined) {
    return <Alert severity="error">No results</Alert>;
  }

  if (loading) {
    return <Progress />;
  } else if (error) {
    return <Alert severity="error">{error.message}</Alert>;
  }

  const types = [...new Set(checkResults.map(({ check }) => check.type))];
  const checkResultRenderers = api.getCheckResultRenderers(types);

  return (
    <List className={classes.list}>
      {checkResults.map((result, index) => (
        <ListItem key={result.check.id} className={classes.listItem}>
          <ListItemText
            key={index}
            primary={result.check.name}
            className={classes.listItemText}
          />
          {checkResultRenderers
            .find(({ type }) => type === result.check.type)
            ?.component(result) ?? (
            <Alert severity="error">Unknown type.</Alert>
          )}
        </ListItem>
      ))}
    </List>
  );
}
