import React from 'react';
import {useAsync} from "react-use";
import Alert from "@material-ui/lab/Alert";


import { useApi } from '@backstage/core-plugin-api';
import { Progress } from '@backstage/core-components';
import { opsgenieApiRef } from '@k-phoen/backstage-plugin-opsgenie';
import {IncidentsTable} from "./IncidentsTable";

export const OngoingIncidents = () => {
  const opsgenieApi = useApi(opsgenieApiRef);

  const {value, loading, error} = useAsync(async () => await opsgenieApi.getIncidents({
    query: "status:open",
  }));

  if (loading) {
    return <Progress />;
  } else if (error) {
    return (
      <Alert data-testid="error-message" severity="error">
        {error.message}
      </Alert>
    );
  }

  return (
    <IncidentsTable incidents={value!} />
  );
};
