import React from 'react';

type Classes = {
  svg: string;
  path: string;
}

export const TemplateBackstageLogo = ({ classes }: { classes: Classes }) => {
  return (


    <svg className={classes.svg} xmlns="http://www.w3.org/2000/svg" id="Layer_2" width="2695" height="2013" viewBox="0 0 2695.4 2013.2">
      <switch>
        <g>
          <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="1348.7" y1="1392.3" x2="1348.7" y2="51.7">
            <stop offset="0" stopColor="#2584C6"/>
            <stop offset="1" stopColor="#2DAAE1"/>
          </linearGradient>
          <path className="st0"
                d="M460.9 1299c-128.5-92.3-243.7-301.7-161.2-528.1s306.2-325.9 464.6-304.9c-3.9-121.8 43.1-241.6 161.2-335.8 123.6-98.6 414.7-140.8 576.3 97.9 68.4-90.3 314.8-93.9 332.2 126.8 87-7.3 346.4-25.2 517.7 252 171.3 277.2 9.8 582.3-137.9 682.5 -147.7 100.3-236.3 103-403.3 103 -18.5-47.2-51.3-87.6-67.8-105.4 -16.4-17.8-32.2-41.1-30.1-89 2.1-47.9 7.5-69.1 47.9-93.8 40.4-24.6 58.2-39.7 65.7-45.9 7.5-6.2 20.5-8.2 35.6-8.2s50.7-9.6 58.2-14.4c7.5-4.8 30.1-1.4 53.4 1.4s73.2 14.4 101.3-2.7c28.1-17.1 13.7-47.2 56.8-52 43.1-4.8 67.1-14.4 70.5-91.7 20.5-4.8 22.6-32.9 19.9-41.1 -2.7-8.2-15.1-19.9-30.8-21.2s-18.5-14.4-35.6-26.7c-17.1-12.3-116.4-70.2-129.4-79.6 -17.8-38.8-18.5-57.3-58.2-83.3 -39.7-26-117.7-67.8-151.3-84.2 -33.5-16.4-45.9-19.9-71.9-19.9 -26 0-45.9 0-45.9 0s-17.8-50-64.3-67.1c-46.5-17.1-75.3 19.9-91 54.8 -17.1-2.7-34.9 0-54.1 44.5 -18.5 14.4-117.7 52.7-181.4 67.1 -63.7-7.5-173.9-17.1-234.1 4.1 -60.2 21.2-130.7 85.6-180.7 158.8 -50 73.2-58.2 111.6-129.4 193S460.9 1299 460.9 1299z"/>
          <g>
            <path className={classes.path}
                  d="M304.4 1713c-1.6 2.2-3.1 3.8-4.7 5 -1.6 1.2-3.8 1.8-6.7 1.8 -2.9 0-6.1-1.2-9.5-3.7 -3.4-2.4-7.8-5.1-13-8 -5.3-2.9-11.7-5.6-19.2-8 -7.5-2.4-16.7-3.7-27.7-3.7 -14.4 0-27.2 2.6-38.3 7.7 -11.1 5.2-20.4 12.6-28 22.4 -7.5 9.8-13.2 21.6-17 35.4 -3.8 13.9-5.7 29.4-5.7 46.5 0 18 2 33.9 6.1 47.9 4.1 13.9 9.9 25.7 17.3 35.1 7.4 9.5 16.4 16.7 27.1 21.7 10.6 5 22.6 7.5 35.9 7.5 12.7 0 23.1-1.5 31.3-4.5 8.2-3 15-6.4 20.5-10.1 5.5-3.7 9.9-7.1 13.5-10.1 3.5-3 7-4.5 10.5-4.5 4.5 0 7.8 1.7 9.9 5l14.6 19c-12.9 15.8-29 27.4-48.3 34.7 -19.3 7.3-39.7 11-61.2 11 -18.5 0-35.8-3.4-51.6-10.2 -15.9-6.8-29.7-16.7-41.4-29.7 -11.7-13-20.9-28.9-27.7-47.9 -6.7-18.9-10.1-40.5-10.1-64.7 0-22 3.1-42.4 9.2-61.2 6.1-18.7 15.1-34.9 26.9-48.4 11.8-13.6 26.4-24.1 43.8-31.8 17.4-7.6 37.3-11.4 59.7-11.4 20.7 0 39 3.4 55 10.1 16 6.7 30.1 16.2 42.4 28.5L304.4 1713z"/>
            <path className={classes.path} d="M433.5 1561.6v395.2H381.4V1561.6H433.5z"/>
            <path className={classes.path}
                  d="M645.4 1655.7c21.7 0 41.2 3.6 58.7 10.8 17.5 7.2 32.3 17.5 44.5 30.7 12.2 13.3 21.6 29.3 28.1 48.1 6.5 18.8 9.8 39.9 9.8 63.1 0 23.4-3.3 44.5-9.8 63.2 -6.5 18.7-15.9 34.7-28.1 48 -12.2 13.3-27 23.5-44.5 30.6 -17.5 7.1-37 10.7-58.7 10.7 -21.7 0-41.2-3.6-58.7-10.7 -17.5-7.1-32.3-17.3-44.6-30.6 -12.3-13.3-21.8-29.3-28.4-48 -6.6-18.7-10-39.8-10-63.2 0-23.2 3.3-44.2 10-63.1 6.6-18.8 16.1-34.9 28.4-48.1 12.3-13.3 27.2-23.5 44.6-30.7C604.1 1659.3 623.7 1655.7 645.4 1655.7zM645.4 1920.2c29.3 0 51.1-9.8 65.6-29.4 14.4-19.6 21.7-47 21.7-82.1 0-35.3-7.2-62.8-21.7-82.5 -14.4-19.7-36.3-29.6-65.6-29.6 -14.8 0-27.7 2.5-38.6 7.6 -10.9 5.1-20 12.4-27.4 22 -7.3 9.6-12.8 21.3-16.4 35.3 -3.6 13.9-5.4 29.7-5.4 47.3s1.8 33.3 5.4 47.1c3.6 13.9 9.1 25.5 16.4 35 7.3 9.5 16.4 16.7 27.4 21.8C617.6 1917.7 630.5 1920.2 645.4 1920.2z"/>
            <path className={classes.path}
                  d="M895.9 1660.4v189.1c0 22.5 5.2 39.8 15.5 52.1 10.3 12.3 25.9 18.5 46.8 18.5 15.2 0 29.6-3.6 43-10.8 13.5-7.2 25.8-17.3 37.2-30.2v-218.6h52.1v296.5h-31c-7.4 0-12.1-3.6-14-10.8l-4.1-31.9c-12.9 14.2-27.3 25.7-43.3 34.4 -16 8.7-34.3 13-55 13 -16.2 0-30.5-2.7-42.9-8 -12.4-5.4-22.8-12.9-31.2-22.7 -8.4-9.8-14.7-21.6-18.9-35.4 -4.2-13.8-6.3-29.2-6.3-45.9v-189.1H895.9z"/>
            <path className={classes.path}
                  d="M1384.9 1956.8c-7.4 0-12.1-3.6-14-10.8l-4.7-36c-12.7 15.4-27.2 27.8-43.5 37 -16.3 9.3-35 13.9-56 13.9 -17 0-32.4-3.3-46.2-9.8 -13.9-6.5-25.7-16.1-35.4-28.8 -9.8-12.7-17.3-28.5-22.5-47.4 -5.3-18.9-7.9-40.7-7.9-65.3 0-21.8 2.9-42.2 8.8-61 5.9-18.8 14.3-35.2 25.3-49 11-13.8 24.4-24.7 40.2-32.6 15.8-7.9 33.7-11.8 53.8-11.8 18.1 0 33.7 3.1 46.5 9.2 12.9 6.1 24.4 14.8 34.5 25.9V1561.6h52.1v395.2H1384.9zM1283.9 1918.8c17 0 31.9-3.9 44.6-11.7 12.8-7.8 24.5-18.8 35.3-33.1v-143.4c-9.6-12.9-20-21.9-31.5-27.1 -11.4-5.2-24-7.7-37.9-7.7 -27.7 0-49 9.8-63.8 29.5 -14.8 19.7-22.2 47.8-22.2 84.3 0 19.3 1.7 35.9 5 49.6 3.3 13.8 8.2 25.1 14.6 33.9s14.3 15.4 23.7 19.5S1271.8 1918.8 1283.9 1918.8z"/>
            <path className={classes.path}
                  d="M1505.2 1956.8V1561.6h52.4v141.5c12.3-14.2 26.4-25.7 42.3-34.4 15.9-8.7 34.1-13 54.6-13 17.2 0 32.7 3.2 46.5 9.7 13.8 6.4 25.6 16.1 35.4 28.8 9.7 12.8 17.3 28.6 22.5 47.4 5.3 18.8 7.9 40.5 7.9 65.1 0 21.9-2.9 42.2-8.8 61 -5.9 18.8-14.3 35.1-25.3 48.9 -11 13.8-24.5 24.6-40.4 32.5 -15.9 7.9-33.8 11.8-53.7 11.8 -19.1 0-35.4-3.7-48.7-11.1 -13.4-7.4-25-17.8-35-31l-2.6 26.9c-1.6 7.4-6 11.1-13.5 11.1H1505.2zM1637.5 1697.2c-17 0-31.8 3.9-44.6 11.7 -12.8 7.8-24.5 18.8-35.3 33.1v143.4c9.4 12.9 19.8 21.9 31.2 27.2s24.2 7.9 38.2 7.9c27.7 0 49-9.8 63.8-29.5 14.8-19.7 22.2-47.8 22.2-84.3 0-19.3-1.7-35.9-5.1-49.7 -3.4-13.8-8.3-25.2-14.8-34.1 -6.4-8.9-14.3-15.4-23.7-19.5C1660 1699.3 1649.4 1697.2 1637.5 1697.2z"/>
            <path className={classes.path}
                  d="M1948 1655.7c17.8 0 34.2 3 49.2 8.9 15 6 28 14.5 38.9 25.8 10.9 11.2 19.5 25.1 25.6 41.6 6.1 16.5 9.2 35.3 9.2 56.3 0 8.2-0.9 13.7-2.6 16.4 -1.8 2.7-5.1 4.1-10 4.1h-197.2c0.4 18.7 2.9 35 7.6 48.9 4.7 13.8 11.1 25.4 19.3 34.7 8.2 9.3 17.9 16.2 29.3 20.8 11.3 4.6 24 6.9 38 6.9 13.1 0 24.3-1.5 33.8-4.5 9.5-3 17.6-6.3 24.4-9.8 6.8-3.5 12.5-6.8 17.1-9.8 4.6-3 8.5-4.5 11.9-4.5 4.3 0 7.6 1.7 10 5l14.6 19c-6.4 7.8-14.1 14.6-23.1 20.3 -9 5.7-18.6 10.5-28.8 14.2 -10.2 3.7-20.8 6.5-31.7 8.3 -10.9 1.9-21.8 2.8-32.5 2.8 -20.5 0-39.4-3.5-56.6-10.4 -17.3-6.9-32.2-17.1-44.8-30.4 -12.6-13.4-22.4-29.9-29.4-49.6 -7-19.7-10.5-42.3-10.5-67.9 0-20.7 3.2-40 9.5-57.9 6.3-18 15.5-33.5 27.4-46.7 11.9-13.2 26.4-23.5 43.6-31C1907.2 1659.4 1926.5 1655.7 1948 1655.7zM1949.1 1694c-25.2 0-45 7.3-59.4 21.8 -14.4 14.5-23.4 34.7-26.9 60.4h161.3c0-12.1-1.7-23.2-5-33.2 -3.3-10-8.2-18.7-14.6-26 -6.4-7.3-14.3-13-23.6-17C1971.6 1696 1961 1694 1949.1 1694z"/>
            <path className={classes.path}
                  d="M2355 1956.8h-23.1c-5.1 0-9.2-0.8-12.3-2.3 -3.1-1.6-5.2-4.9-6.1-10l-5.9-27.5c-7.8 7-15.4 13.3-22.8 18.9 -7.4 5.6-15.2 10.2-23.4 14.1 -8.2 3.8-16.9 6.7-26.2 8.6 -9.3 1.9-19.6 2.9-30.9 2.9 -11.5 0-22.3-1.6-32.3-4.8s-18.8-8.1-26.2-14.5c-7.4-6.5-13.3-14.6-17.7-24.5 -4.4-9.9-6.6-21.6-6.6-35.1 0-11.7 3.2-23.1 9.7-33.9 6.4-10.9 16.8-20.5 31.2-28.9 14.3-8.4 33.1-15.3 56.3-20.7 23.2-5.4 51.6-8.1 85.2-8.1v-23.3c0-23.2-4.9-40.7-14.8-52.6 -9.9-11.9-24.4-17.8-43.8-17.8 -12.7 0-23.4 1.6-32 4.8s-16.2 6.8-22.5 10.8c-6.4 4-11.8 7.6-16.4 10.8 -4.6 3.2-9.1 4.8-13.6 4.8 -3.5 0-6.6-0.9-9.2-2.8 -2.6-1.9-4.7-4.1-6.3-6.9l-9.4-16.7c16.4-15.8 34-27.6 53-35.4 18.9-7.8 39.9-11.7 62.9-11.7 16.6 0 31.3 2.7 44.2 8.2s23.7 13.1 32.5 22.8c8.8 9.8 15.4 21.6 19.9 35.4 4.5 13.8 6.7 29.1 6.7 45.6V1956.8zM2219.8 1924.9c9.2 0 17.6-0.9 25.2-2.8 7.6-1.9 14.8-4.5 21.5-7.9 6.7-3.4 13.2-7.6 19.3-12.4 6.1-4.9 12.1-10.4 18-16.7v-61.2c-24 0-44.4 1.5-61.2 4.6 -16.8 3-30.4 7-41 11.9 -10.5 4.9-18.2 10.7-23 17.4 -4.8 6.7-7.2 14.1-7.2 22.4 0 7.8 1.3 14.6 3.8 20.3 2.5 5.7 6 10.4 10.2 14 4.3 3.6 9.4 6.3 15.2 7.9C2206.7 1924.1 2213 1924.9 2219.8 1924.9z"/>
            <path className={classes.path}
                  d="M2434.1 1956.8v-296.5h29.9c5.7 0 9.6 1.1 11.7 3.2 2.1 2.1 3.6 5.9 4.4 11.1l3.5 46.2c10.1-20.7 22.7-36.8 37.6-48.4 14.9-11.6 32.4-17.4 52.5-17.4 8.2 0 15.6 0.9 22.2 2.8 6.6 1.9 12.8 4.4 18.4 7.7l-6.7 38.9c-1.4 4.9-4.4 7.3-9.1 7.3 -2.7 0-6.9-0.9-12.6-2.8 -5.7-1.8-13.6-2.8-23.7-2.8 -18.1 0-33.3 5.3-45.5 15.8 -12.2 10.5-22.4 25.8-30.6 45.9v188.8H2434.1z"/>
          </g>
        </g>
      </switch>
    </svg>
  );
};
