import {Content, InfoCard, Page} from '@backstage/core-components';
import {HomePageCompanyLogo, HomePageStarredEntities, HomePageToolkit} from '@backstage/plugin-home';
import {HomePageSearchBar} from '@backstage/plugin-search';
import {Grid, makeStyles} from '@material-ui/core';
import React from 'react';
import {TemplateBackstageLogo} from './TemplateBackstageLogo';
import {OngoingIncidents} from "./OngoingIncidents";
import { HomePageCalendar } from '@backstage-community/plugin-gcalendar';
import {OnCallList} from "./OnCall";

const useStyles = makeStyles(theme => ({
  searchBar: {
    display: 'flex',
    maxWidth: '60vw',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
    padding: '8px 0',
    borderRadius: '50px',
    margin: 'auto',
  },
}));

const useLogoStyles = makeStyles(theme => ({
  container: {
    margin: theme.spacing(5, 0),
  },
  svg: {
    width: 'auto',
    height: 100,
    fill: 'url(#SVGID_1_)',
  },
  path: {
    fill: '#373736',
  },
}));

const tools = [
  {
    url: 'https://undercloud.cbws.xyz',
    label: 'Undercloud',
    icon: <img src="https://object-storage-ca-ymq-1.vexxhost.net/swift/v1/6e4619c416ff4bd19e1c087f27a43eea/www-images-prod/openstack-logo/2016R/OpenStack-Logo-Mark.svg" />,
  },
  {
    url: 'https://vault.cbws.xyz',
    label: 'Vault',
    icon:
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 73.6 72">
        <path d="M37.6 36.8c1.2 0 2.1-1 2.1-2.1s-.9-2.1-2.1-2.1-2.1 1-2.1 2.1.9 2.1 2.1 2.1zM31.3 31c1.2 0 2.1-1 2.1-2.1 0-1.2-.9-2.1-2.1-2.1-1.2 0-2.1 1-2.1 2.1 0 1.2.9 2.1 2.1 2.1zm6.3 0c1.2 0 2.1-1 2.1-2.1 0-1.2-.9-2.1-2.1-2.1s-2.1 1-2.1 2.1c0 1.2.9 2.1 2.1 2.1zm6.3 0c1.2 0 2.1-1 2.1-2.1 0-1.2-.9-2.1-2.1-2.1-1.2 0-2.1 1-2.1 2.1 0 1.2 1 2.1 2.1 2.1zm-12.6-5.7c1.2 0 2.1-1 2.1-2.1 0-1.2-.9-2.1-2.1-2.1-1.2 0-2.1 1-2.1 2.1 0 1.2.9 2.1 2.1 2.1zm6.3 0c1.2 0 2.1-1 2.1-2.1 0-1.2-.9-2.1-2.1-2.1s-2.1 1-2.1 2.1c0 1.2.9 2.1 2.1 2.1zm6.3 0c1.2 0 2.1-1 2.1-2.1 0-1.2-.9-2.1-2.1-2.1-1.2 0-2.1 1-2.1 2.1 0 1.2 1 2.1 2.1 2.1zm-6.3-5.7c1.2 0 2.1-1 2.1-2.1 0-1.2-.9-2.1-2.1-2.1s-2.1 1-2.1 2.1.9 2.1 2.1 2.1zm6.3 0c1.2 0 2.1-1 2.1-2.1 0-1.2-.9-2.1-2.1-2.1-1.2 0-2.1 1-2.1 2.1s1 2.1 2.1 2.1zm-12.6 0c1.2 0 2.1-1 2.1-2.1 0-1.2-.9-2.1-2.1-2.1-1.2 0-2.1 1-2.1 2.1s.9 2.1 2.1 2.1zM36.8 72L0 0h73.6L36.8 72z"/>
      </svg>,
  },
  {
    url: 'https://awx.cloudbear.network/',
    label: 'AWX',
    icon: <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 230.39711 218.6199" aria-label="BookStack Logo">
      <g strokeLinejoin="round" fillRule="evenodd" transform="translate(-245.27 -58.434)" stroke="#0288d1" strokeWidth="6" fill="#fff">
        <g strokeLinecap="round">
          <path d="m343.79 238.6 128.88-74.409-92.058-53.15-128.88 74.409z"></path>
          <path d="m251.73 185.45v21.26l92.058 53.15 128.88-74.409v-21.26"></path>
          <path d="m343.79 274.03-92.058-53.15s-7.5-16.918 0-28.346l92.058 53.15 128.88-74.409v28.346l-128.88 74.409"></path>
          <path d="m343.79 188.99 128.88-74.41-92.06-53.146-128.88 74.406z"></path>
          <path d="m343.79 188.99 128.88-74.409 0.00001 28.346-128.88 74.409-92.058-53.15s-6.0714-17.632 0-28.346z"></path>
          <path d="m343.79 245.69-92.058-53.15s-7.5-16.918 0-28.346l92.058 53.15 128.88-74.409-0.00001 28.346-128.88 74.409"></path>
        </g>
        <path d="m402.09 73.836-55.234 31.89 21.48 1.7716 3.0686 12.402 55.235-31.89z"></path>
      </g>
    </svg>
  },
  {
    url: 'https://wiki.lichtpuntjeholding.nl/',
    label: 'Wiki',
    icon: <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 230.39711 218.6199" aria-label="BookStack Logo">
      <g strokeLinejoin="round" fillRule="evenodd" transform="translate(-245.27 -58.434)" stroke="#0288d1" strokeWidth="6" fill="#fff">
        <g strokeLinecap="round">
          <path d="m343.79 238.6 128.88-74.409-92.058-53.15-128.88 74.409z"></path>
          <path d="m251.73 185.45v21.26l92.058 53.15 128.88-74.409v-21.26"></path>
          <path d="m343.79 274.03-92.058-53.15s-7.5-16.918 0-28.346l92.058 53.15 128.88-74.409v28.346l-128.88 74.409"></path>
          <path d="m343.79 188.99 128.88-74.41-92.06-53.146-128.88 74.406z"></path>
          <path d="m343.79 188.99 128.88-74.409 0.00001 28.346-128.88 74.409-92.058-53.15s-6.0714-17.632 0-28.346z"></path>
          <path d="m343.79 245.69-92.058-53.15s-7.5-16.918 0-28.346l92.058 53.15 128.88-74.409-0.00001 28.346-128.88 74.409"></path>
        </g>
        <path d="m402.09 73.836-55.234 31.89 21.48 1.7716 3.0686 12.402 55.235-31.89z"></path>
      </g>
    </svg>,
  }
]

export const HomePage = () => {
  const classes = useStyles();
  const {svg, path, container} = useLogoStyles();

  /* We will shortly compose a pretty homepage here. */
  return (
    <Page themeId="home">
      <Content>
        <Grid container justifyContent="center" spacing={6}>
          <HomePageCompanyLogo
            className={container}
            logo={<TemplateBackstageLogo classes={{svg, path}}/>}
          />
          <Grid container item xs={12} alignItems="center" direction="row">
            <HomePageSearchBar
              classes={{root: classes.searchBar}}
              placeholder="Search"
            />
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={12} md={6}>
              <HomePageStarredEntities/>
            </Grid>
            <Grid item xs={12} md={6}>
              <HomePageToolkit
                tools={tools}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InfoCard title="On call">
                <OnCallList />
              </InfoCard>
            </Grid>
            <Grid item xs={12} md={6}>
              <InfoCard title="Ongoing incidents">
                {/* placeholder for content */}
                <OngoingIncidents />
              </InfoCard>
            </Grid>
            <Grid item xs={12} md={6}>
              <HomePageCalendar />
            </Grid>
          </Grid>
        </Grid>
      </Content>
    </Page>
  );
};
