import React from 'react';
import {Chip, Tooltip, withStyles} from '@material-ui/core';
import Link from '@material-ui/core/Link';

import { Table, TableColumn } from '@backstage/core-components';
import { useApi } from '@backstage/core-plugin-api';
import {opsgenieApiRef} from "@k-phoen/backstage-plugin-opsgenie";

export interface Incident {
  id: string; // UUID
  tinyId: string;
  message: string; // "Our servers are in danger"
  status: string; // TODO: enum
  tags: string[]; // ["Critical", "owner:some-team", "service:business-critical"]
  priority: string; // TODO: enum
  createdAt: string;
  updatedAt: string;
  impactStartDate: string;
  impactEndDate: string;
  responders: ResponderRef[];
  extraProperties: Record<string, string>;
}

export interface ResponderRef {
  id: string; // UUID
  type: string; // "team", "user"
}

const P5Chip = withStyles({
  root: {
    backgroundColor: '#00857A',
    color: 'white',
  }
})(Chip);
const P4Chip = withStyles({
  root: {
    backgroundColor: '#36b37e',
    color: 'white',
  }
})(Chip);
const P3Chip = withStyles({
  root: {
    backgroundColor: '#ffab00',
    color: 'white',
  }
})(Chip);
const P2Chip = withStyles({
  root: {
    backgroundColor: '#ff7452',
    color: 'white',
  }
})(Chip);
const P1Chip = withStyles({
  root: {
    backgroundColor: '#bf2600',
    color: 'white',
  }
})(Chip);

const priorityLabels = {
  'P5': 'Informational',
  'P4': 'Low',
  'P3': 'Moderate',
  'P2': 'High',
  'P1': 'Critical',
} as Record<string, string>;

export const PriorityChip = ({ priority }: {priority: string}) => {
  let chip = <></>;
  switch (priority) {
    case 'P5':
      chip = <P5Chip label={priority} size="small" />;
      break;
    case 'P4':
      chip = <P4Chip label={priority} size="small" />;
      break;
    case 'P3':
      chip = <P3Chip label={priority} size="small" />;
      break;
    case 'P2':
      chip = <P2Chip label={priority} size="small" />;
      break;
    case 'P1':
      chip = <P1Chip label={priority} size="small" />;
      break;
    default:
      chip = <Chip label={priority} size="small" />;
  }

  return (
    <Tooltip title={priorityLabels[priority]}>
      <div>{chip}</div>
    </Tooltip>
  )
};

export const IncidentsTable = ({ incidents }: {incidents: Incident[]}) => {
  const opsgenieApi = useApi(opsgenieApiRef);
  const smallColumnStyle = {
    width: '5%',
    maxWidth: '5%',
  };
  const mediumColumnStyle = {
    width: '10%',
    maxWidth: '10%',
  };

  const columns: TableColumn<Incident>[] = [
    {
      title: 'ID',
      field: 'tinyId',
      highlight: true,
      cellStyle: smallColumnStyle,
      headerStyle: smallColumnStyle,
      render: rowData => <Link href={opsgenieApi.getIncidentDetailsURL(rowData)}>#{(rowData).tinyId}</Link>
    },
    {
      title: 'Priority',
      field: 'priority',
      cellStyle: smallColumnStyle,
      headerStyle: smallColumnStyle,
      render: rowData => <PriorityChip priority={rowData.priority} />
    },
    {
      title: 'Status',
      field: 'status',
      cellStyle: smallColumnStyle,
      headerStyle: smallColumnStyle,
      render: rowData => <Chip  label={rowData.status} color={rowData.status === 'open' ? 'secondary' : 'default'} size="small" />
    },
    {
      title: 'Description',
      field: 'message',
    },
    {
      title: 'Tags',
      field: 'tags',
      render: rowData => <>{rowData.tags.map((tag, i) => <Chip label={tag} key={i} size="small" />)}</>
    },
    {
      title: 'Updated At',
      field: 'updatedAt',
      type: 'datetime',
      dateSetting: {locale: 'en-UK'},
      cellStyle: mediumColumnStyle,
      headerStyle: mediumColumnStyle,
    },
  ];

  return (
    <Table
      options={{
        sorting: true,
        search: true,
        paging: true,
        actionsColumnIndex: -1,
        pageSize: 2,
        padding: 'dense',
      }}
      localization={{ header: { actions: undefined } }}
      columns={columns}
      data={incidents}
    />
  );
};
