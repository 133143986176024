import { createApiRef } from '@backstage/core-plugin-api';

export const CapApiRef = createApiRef<CapApi>({
  id: 'plugin.cap.service',
});

export type Application = {
  id: string;
  slug: string;
  name: string;
};

export type Deployment = {
  id: string;
  currentVersion: string;
  desiredVersion: string;
  environment: Environment;
};

export type Environment = {
  slug: string;
  name: string;
};

export type JsonApiRelationship = {
  links: {
    self: string;
    related: string;
  }
  data: JsonApiReference[] | JsonApiReference;
}

export type JsonApiReference = {
  type: string;
  id: string;
}

export type JsonApiObject<T> = {
  type: string;
  id: string;
  attributes: T
  relationships: JsonApiRelationships
}

export interface JsonApiRelationships {
  [key: string]: JsonApiRelationship;
}

export type Response = {
  data: JsonApiObject<any>[];
  included: JsonApiObject<any>[];
};

export interface ApplicationList {
  getApplicationsData: Application[];
}

export interface ApplicationGet {
  id: string;
  getApplicationData: Application;
  deployments: Deployment[];
}

export interface ApplicationDiff {
  patch: string;
}

export type CapApi = {
  getApplications(
    slug: string,
  ): Promise<ApplicationList>;

  getApplication(
    slug: string,
  ): Promise<ApplicationGet>;

  getDiff(
    id: string, envSlug: string
  ): Promise<ApplicationDiff>;
};
